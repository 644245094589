<template>
    <div class="content-box">
        <Title/>

        <Report/>
    </div>
</template>

<script>
import { Report } from 'shared'

export default {
    name: 'report',
    components: {
        Report
    },
    data() {
        return {
            params: {
                json: 0
            }
        }
    },
    created() {
        this.$store.commit('textparams', this.params)
        this.$store.commit('changeKeys', '报告 房')
        this.$store.dispatch('getText')
    }
}
</script>